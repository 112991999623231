import { PDFDownloadLink } from '@react-pdf/renderer';
import queryString from 'query-string';
import saveAs from 'file-saver';
import { find } from 'lodash';
import React, { useCallback, useContext } from 'react';
import { Download, File, Phone, Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { FundsTransactionsTable, Invoice, PaymentTransactionsTable } from '../../..';
import {
    AppLayout,
    AuthLayout,
    CustomPrimaryButton,
    CustomTable,
    TabLink,
    TopBar,
    DataTable,
} from '../../../../components';
import { Breadcrumb } from '../../../../components/breadcrumbs/breadcrumbs';
import { FundWalletModal, RenewalModal, PaymentModal } from '../../../../components/wallet';
import { AuthContext } from '../../../../context';
import { AuthenticatedUser } from '../../../../models/response';
import { Transaction, User, UserFiles } from '../../../../models/response';
import { AirportsThunk, ApplicationState, NotificationsThunk, TransactionsThunk } from '../../../../store';
import { UsersThunk } from '../../../../store/users/users.thunk';
import { ApiClient } from '../../../../util/api-client';
import { NotificationTypes, Roles, TransactionTypes } from '../../../../util/constants';
import { formatDate } from '../../../../util/misc/formatters';
import { ClientPageTable } from './view-client-page-table';
import { ViewClientPageStyleWrapper } from './view-client-page.style';
import { TransactionsService } from '../../../../services';
import { formatCurrency } from '../../../../util/misc';
import moment from 'moment';

const InsufficientFundsWrapper = styled.div`
    width: 100%;
    font-size: 0.85rem;
    padding: 0.75em;
    color: #f4f4f4;
    background-color: rgb(247, 123, 114);
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;

    button {
        background-color: #f4f4f4;
        color: rgb(247, 123, 114);
        border: none;
        padding: 0.5em 1em;
        border-radius: 8px;
        font-weight: 700;
        transition: color 0.5s ease-in;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: rgba(247, 123, 114, 0.863);
            background-color: #f4f4f4cc;
        }
    }
`;
export const ViewClientPage: React.FC<{}> = () => {
    const [activeTab, setActiveTab] = React.useState(1);

    const [isFunding, setFundingModal] = React.useState(false);
    const [isRenewal, setRenewalModal] = React.useState(false);
    const [amount, setAmount] = React.useState(0);
    const [selectedRenewalDate, setSelectedRenewalDate] = React.useState<string | null>(null);
    const [renewalPaymentId, setRenewalPaymentId] = React.useState<number | null>(null);
    const [isFundingWallet, setFundingWallet] = React.useState<boolean>(false);
    const [balance, setBalance] = React.useState<number>(0);
    // const user = useContext(AuthContext.Context).user as AuthenticatedUser;
    const { search } = useLocation();

    const { client_id } = useParams<any>();
    const { airports } = useSelector(({ airports }: ApplicationState) => airports);

    const {
        openTransaction,
        transactionType: transaction,
        transactionTypeMeta,
    } = useSelector((state: ApplicationState) => state.transactions);
    const { user: loggedInUser } = useContext(AuthContext.Context)!;
    const {
        userDetails: client,
        usersBookings: bookings,
        userBookingMeta: meta,
    } = useSelector((state: ApplicationState) => state.users);
    const dispatch = useDispatch();
    const pageNumber = React.useMemo(() => +(queryString.parse(search) as any).page || 1, [search]);

    const handleRenewalComplete = (value: string) => {
        console.log('Renewal completed with value:', value);

        setSelectedRenewalDate(value);
    };

    React.useEffect(() => {
        dispatch(UsersThunk.getUser(client_id));
        dispatch(AirportsThunk.list());

        if (activeTab === 1) {
            dispatch(UsersThunk.getUserBoking(client_id, pageNumber));
        } else if (activeTab === 2) {
            dispatch(
                TransactionsThunk.retrieveTransactionTypebyId(client_id, TransactionTypes.Fund, () => {}, pageNumber)
            );
        } else if (activeTab === 4) {
            dispatch(TransactionsThunk.fetchPayRenewalId(client_id, pageNumber));
            TransactionsService.getWalletBalance(client_id)
                .then(({ data }) => {
                    const newBalance = data?.balance.balance ?? 0;
                    console.log('Remaining balance in wallet now:', newBalance);
                    setBalance(newBalance);
                })
                .catch((err) => console.log({ err }));
        }
    }, [dispatch, client_id, activeTab, pageNumber]);

    React.useEffect(() => {
        TransactionsService.getWalletBalance(client_id)
            .then(({ data }) => {
                setAmount(data?.balance.balance ?? 0);
            })
            .catch((err) => console.log({ err }));
    }, []);
    const price = 200000;
    const name = !client ? '' : client?.business_Name ?? `${client?.firstName} ${client?.lastName}`;

    const breadcrumbs: Breadcrumb[] = [
        { name: 'Dashboard', path: '/app/dashboard' },
        { name: 'All Clients', path: '/app/clients' },
        {
            name,
            path: window.location.href,
        },
    ];

    const links: TabLink[] = React.useMemo(
        () => [
            {
                index: 1,
                name: `Activities`,
            },
            {
                index: 2,
                name: `Funds`,
            },
            {
                index: 4,
                name: `Registrations`,
            },
            ...(client?.clientFiles.length ? [{ index: 3, name: 'Files' }] : []),
        ],
        [client, name]
    );

    const exportFile = React.useCallback(
        async (client) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            const name = client.firstName ? `${client.firstName} ${client.lastName}` : client.business_Name;

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/users/form/${client && client.id}`,
                    config
                );

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, name + '.pdf');
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    const exportInvoice = useCallback(
        async (transaction: User) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            const name = transaction?.business_Name ?? '';

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/users/export-registration-invoice?userid=${
                        transaction && transaction.id
                    }`,
                    config
                );

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, name + '.pdf');
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    const fetchFile = React.useCallback(
        async (file: UserFiles) => {
            const config: RequestInit = {
                ...ApiClient.getFetchConfig(),
            };

            try {
                dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading [${file.name}]`));

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/clientfile/${file.id}`, config);

                try {
                    const downloadedFile = await response.blob();
                    saveAs(downloadedFile, file.name + file.fileType);
                } catch (e) {
                    dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
                }
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
            }
        },
        [dispatch]
    );

    const exportClientActivitiesFile = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/booking-services/export-client-activities/${client && client.id}`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Client Activities';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    const exportClienFunds = async () => {
        const config: RequestInit = {
            ...ApiClient.getFetchConfig(),
        };

        try {
            dispatch(NotificationsThunk.create(NotificationTypes.Info, `Downloading document`));

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/transactions/export-client-fundings/${client && client.id}`,
                config
            );

            try {
                const downloadedFile = await response.blob();
                const documentName = 'Client Fundings';
                saveAs(downloadedFile, documentName + '.csv');
            } catch (e) {
                dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error saving file'));
            }
        } catch (e) {
            dispatch(NotificationsThunk.create(NotificationTypes.Error, 'Error downloading file'));
        }
    };

    return (
        <AuthLayout showSidebar={false}>
            <FundWalletModal
                isOpen={isFunding}
                onComplete={(value) => setAmount((prev) => prev + value)}
                setIsOpen={setFundingModal}
                client={client_id}
            />
            <RenewalModal
                isOpen={isRenewal}
                onComplete={(dateString) => handleRenewalComplete(Date.parse(dateString).toString())}
                setIsOpen={setRenewalModal}
                client={client_id}
                pageNumber={pageNumber}
            />
            <ViewClientPageStyleWrapper>
                <TopBar breadcrumbs={breadcrumbs} />
                <AppLayout.Wrapper>
                    <AppLayout.Header>
                        {activeTab === 4 && balance < price && (
                            <InsufficientFundsWrapper className="my-3">
                                {isFundingWallet && (
                                    <FundWalletModal
                                        onComplete={(value) => setBalance((prev) => prev + value)}
                                        isOpen={isFundingWallet}
                                        setIsOpen={setFundingWallet}
                                        client={client_id}
                                    />
                                )}
                                <span>
                                    You do not have enough balance to complete this process. Please fund your wallet.
                                </span>
                                <button onClick={() => setFundingWallet(true)}>Fund wallet</button>
                            </InsufficientFundsWrapper>
                        )}
                        <Col lg={8} className="d-flex align-items-center">
                            <AppLayout.HeaderBackButton />
                            <h3 className="header__title">{name}</h3>
                        </Col>
                        {false && (
                            <Col className="d-flex justify-content-end">
                                <CustomPrimaryButton>
                                    <Phone size={14} strokeWidth={3} />
                                    <span>Contact Client</span>
                                </CustomPrimaryButton>
                            </Col>
                        )}
                    </AppLayout.Header>
                    <Row>
                        <Col lg={3}>
                            <div className="infoBox">
                                <div className="circleBox">
                                    {(client?.firstName
                                        ? client.firstName[0] + client.lastName[0]
                                        : client?.business_Name.slice(0, 1)
                                    )?.toUpperCase()}
                                </div>
                                <h4>{name}</h4>
                                <p>{client?.email}</p>
                                <div className="text-left w-100 px-2">
                                    <p className="mb-2">
                                        <strong>Customer AIN:</strong> {client?.customerAIN ?? 'N/A'}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Updated By: </strong>
                                        {client?.updatedBy ?? 'N/A'}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Airport Name: </strong>
                                        {find(airports, { id: client?.airportId })?.name ?? 'N/A'}
                                    </p>
                                    <p className="mb-2">
                                        <strong>Status: </strong>
                                        {client?.profile_Status}
                                    </p>

                                    {loggedInUser?.role !== Roles.Admin && (
                                        <p className="mb-4">
                                            <strong>RRR Number: </strong>processing...
                                        </p>
                                    )}
                                </div>
                                <Row>
                                    <Col lg={8} className="text-left">
                                        <Button size="sm" color="success" type="button">
                                            {client?.firstName
                                                ? 'Individual'
                                                : client?.name_Of_Government_Officials_Office
                                                ? 'Government Official'
                                                : 'Corporate'}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="text-right">
                                        {client && (
                                            <CustomPrimaryButton
                                                className="inverted small"
                                                onClick={() => exportInvoice(client)}
                                            >
                                                <File size={10} strokeWidth={3} />
                                                <span>Get Receipt</span>
                                            </CustomPrimaryButton>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" className="text-right">
                                        <a
                                            download
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={`${process.env.REACT_APP_BASE_URL}/users/form/${client && client.id}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                exportFile(client);
                                            }}
                                        >
                                            <CustomPrimaryButton className="inverted small">
                                                <Send size={10} strokeWidth={3} />
                                                <span>Export Form</span>
                                            </CustomPrimaryButton>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={9}>
                            <AppLayout.Body>
                                <AppLayout.TabHeader
                                    links={links}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    handleExport={activeTab === 1 ? exportClientActivitiesFile : exportClienFunds}
                                />
                                <AppLayout.TabBody>
                                    <TabContent activeTab={activeTab} className="tab__content">
                                        <TabPane tabId={1}>
                                            {loggedInUser?.role === Roles.AirportProtocolCoordinator ||
                                            loggedInUser?.role === Roles.Admin ? (
                                                <Link to={`/app/client-services/${client_id}`}>
                                                    <Button
                                                        disabled={client?.account_Status ? false : true}
                                                        color="success"
                                                        className="btn btn"
                                                        type="button"
                                                    >
                                                        Book Service
                                                    </Button>
                                                </Link>
                                            ) : null}
                                            {bookings && bookings.length ? (
                                                <ClientPageTable bookings={bookings} meta={meta} />
                                            ) : (
                                                <p>There are no bookings</p>
                                            )}
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <CustomTable showHead={false}>
                                                {client?.clientFiles.map((file, i) => (
                                                    <tr key={i}>
                                                        <td colSpan={2}>
                                                            <div className="d-flex">
                                                                <div className="mr-3">
                                                                    <img
                                                                        src={require('../../../../assets/images/doc.svg')}
                                                                        alt="doc"
                                                                    />
                                                                </div>
                                                                <div className="mr-2">
                                                                    <p>{file.name + file.fileType}</p>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <a
                                                                        download
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        href={`${process.env.REACT_APP_BASE_URL}/users/clientfile/${file.id}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            fetchFile(file);
                                                                        }}
                                                                    >
                                                                        <Download size={12} className="mr-2" />
                                                                        <span>Download</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            </CustomTable>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            {loggedInUser?.role === Roles.Commercial ||
                                            loggedInUser?.role === Roles.Admin ? (
                                                <Button
                                                    disabled={client?.account_Status ? false : true}
                                                    color="success"
                                                    className="btn-btn"
                                                    onClick={() => setFundingModal(true)}
                                                >
                                                    Fund Wallet
                                                </Button>
                                            ) : null}
                                            <div className="float-right">
                                                <strong>Balance: NGN {amount.toLocaleString()}</strong>
                                            </div>

                                            {transaction.length ? (
                                                <FundsTransactionsTable
                                                    transactions={transaction}
                                                    meta={transactionTypeMeta}
                                                />
                                            ) : (
                                                <p>There are no funds to display</p>
                                            )}
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <Row>
                                                <Col
                                                    lg={12}
                                                    className="d-flex justify-content-between align-items-center"
                                                >
                                                    <div>
                                                        <Button
                                                            color="success"
                                                            className="btn-btn"
                                                            onClick={() => {
                                                                setRenewalModal(true);
                                                            }}
                                                            disabled={transaction.length > 0}
                                                        >
                                                            Renewal
                                                        </Button>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <p className="mb-2">
                                                            <strong>Renewal Date: </strong>
                                                            <em>
                                                                {transaction?.[0]?.endDate
                                                                    ? moment(transaction[0].endDate).format(
                                                                          'Do MMMM, YYYY'
                                                                      )
                                                                    : ''}
                                                            </em>
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {transaction.length ? (
                                                <PaymentTransactionsTable
                                                    transactions={transaction}
                                                    meta={transactionTypeMeta}
                                                />
                                            ) : (
                                                <p>There are no payments to display</p>
                                            )}
                                        </TabPane>
                                    </TabContent>
                                </AppLayout.TabBody>
                            </AppLayout.Body>
                        </Col>
                    </Row>
                </AppLayout.Wrapper>
            </ViewClientPageStyleWrapper>
        </AuthLayout>
    );
};
