import { Allow, Equals, IsArray, IsEnum, IsISO8601, MinLength, Validate, ValidateNested } from 'class-validator';
import { Service } from '../../response';
import { BaseModel } from '../base-model';
import { CreatePassenger } from './create-passenger.model';

import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';
import { Airports } from '../../../util/constants';
import { DateAfter } from '../../../util/validation/decorators/dates';

@ValidatorConstraint({ name: 'arrayLength', async: false })
export class ArrayLength implements ValidatorConstraintInterface {
    public validate(array: any[], args: ValidationArguments) {
        return array.length > 0;
    }
}

export class CreateBookingModel implements BaseModel {
    @MinLength(3, {
        message: 'Must be at least $constraint1 character(s) long',
    })
    public Flight_Number: string = '';

    public clientId: string = '';

    @Allow()
    public additionalServiceId: number = 0;

    @DateAfter(undefined, { message: 'Select a date in the future' })
    @IsISO8601({}, { message: 'Invalid date format' })
    public Date_Of_Travel: string = '';

    @IsISO8601({}, { message: 'Invalid time format' })
    public Date_Of_Arrival: string = '';

    @IsEnum(Airports, { message: 'Invalid airport selection' })
    public AirportId: Airports = Airports.ABV;

    @ValidateNested()
    @IsArray({ message: 'Invalid passengers' })
    @Validate(ArrayLength, { message: 'Must have at least one passenger' })
    public Passengers: CreatePassenger[] = [];

    @Allow()
    public Service?: Service;

    @Allow()
    public ServiceId: number = 0;

    @Equals(true, { message: 'You must accept the terms and conditions' })
    public Accept?: boolean = false;

    constructor(service?: Service) {
        this.Service = service;

        if (service) {
            this.ServiceId = service.id;
        }
    }

    public beforeValidation() {
        this.AirportId = +this.AirportId;
        this.clientId = this.clientId;
    }

    public finalize() {
        delete this.Service;
        delete this.Accept;
    }
}
