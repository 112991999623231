import React from 'react';
import { Activity, Bell, Clipboard, CreditCard, DollarSign, Flag, Grid, Home, User, Users } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AuthThunk } from '../../store/auth/auth.thunk';
import { NewRoles, Roles } from '../../util/constants';

interface SidebarLink {
    name: string;
    link: string;
    icon: React.FC<any>;
    inactive?: boolean;
    role?: Roles | Roles[];
}

export const useSidebar = () => {
    const dispatch = useDispatch();

    const handleLogout = React.useCallback(() => {
        dispatch(AuthThunk.logOut());
    }, [dispatch]);

    const links: SidebarLink[] = [
        { name: 'Dashboard', link: '/app/dashboard', icon: Home },
        {
            name: 'All Clients',
            link: '/app/clients',
            icon: Users,
            role: [Roles.Admin, Roles.AirportProtocolCoordinator, Roles.AirportManager, ...NewRoles],
        },
        {
            name: 'All Services',
            link: '/app/services',
            icon: Grid,
            role: [Roles.Client, Roles.Admin],
        },
        {
            name: 'Transactions',
            link: '/app/transaction-services',
            icon: DollarSign,
            role: [
                Roles.Accountant,
                Roles.Auditor,
                Roles.Client,
                Roles.Commercial,
                Roles.Admin,
                Roles.AirportProtocolCoordinator,
            ],
        },
        {
            name: 'Bookings',
            link: '/app/bookings',
            icon: Clipboard,
            role: [Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator],
        },
        {
            name: 'Users',
            link: '/app/users',
            icon: Users,
            role: Roles.Admin,
        },
        // {
        //     name: 'Clients Feedback',
        //     link: '/app/client-feedback',
        //     icon: Flag,
        //     role: [Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator],
        // },
        {
            name: 'Users Activities',
            link: '/app/activities',
            icon: Activity,
            role: [Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator],
        },
        // {
        //     name: 'Vcard',
        //     link: '/app/vcard',
        //     icon: CreditCard,
        //     role: [Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator],
        // },
        {
            name: 'Reporting',
            link: '/app/reporting',
            icon: Flag,
            role: [Roles.Admin, Roles.AirportManager, Roles.AirportProtocolCoordinator],
        },
        {
            name: 'Protocols',
            link: '/app/protocols',
            icon: Users,
            role: [Roles.AirportProtocolCoordinator, Roles.Admin],
        },
        {
            name: 'Notifications',
            link: '/app/notifications',
            icon: Bell,
            inactive: true,
        },
        { name: 'Profile', link: '/app/profile', icon: User },
    ];

    return {
        links,
        handleLogout,
    };
};
