import { Dispatch } from 'redux';
import { CustomerSurveyModel } from '../../models/request';
import { FeedbackService } from '../../services/feedback.service';
import { NotificationTypes } from '../../util/constants';
import { history } from '../../util/history';
import { generateLoader } from '../../util/misc';
import { NotificationsThunk } from '../notifications/notifications.thunk';

export class FeedbackThunk {
    public static submit(survey: CustomerSurveyModel) {
        return async (dispatch: Dispatch) => {
            try {
                const response = await generateLoader(
                    FeedbackService.sumbitSurvey(survey),
                    dispatch,
                    'Submitting survey'
                );

                if (response.data) {
                    NotificationsThunk.create(NotificationTypes.Success, 'Survey submitted successfully')(dispatch);

                    return history.replace('/');
                }

                throw new Error('Survey submission failed');
            } catch (error) {
                // TODO: dispatch error to errorReducer
                // to be implemented
            }
        };
    }
}
